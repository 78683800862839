import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input as ChakraInput,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import type {
  FormControlProps,
  FormLabelProps,
  InputProps as ChakraInputProps,
} from '@chakra-ui/react';
import { useController } from 'react-hook-form';
import type { FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';
import { getFieldErrorMessage, RequiredAsterisk } from './utils';
import type { ReactNode } from 'react';

export type RHFInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = UseControllerProps<TFieldValues, TName> &
  ChakraInputProps & {
    label: ReactNode;
    labelProps?: FormLabelProps;
    hideLabel?: boolean;
    icon?: ReactNode;
    readOnly?: boolean;
    formControlProps?: FormControlProps;
    leftElement?: ReactNode;
    rightElement?: ReactNode;
  };

export function RHFInput<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  icon,
  hideLabel,
  // Controller props
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  labelProps,
  formControlProps,
  leftElement,
  rightElement,
  isRequired,
  w,
  ...inputProps
}: RHFInputProps<TFieldValues, TName>) {
  const { field, formState } = useController({
    defaultValue: '' as any, // This is to prevent silly jest errors regarding controlled switching to uncontrolled errors
    name,
    rules,
    shouldUnregister,
    control,
  });
  const iconColor = mode('gray.600', 'darkmode.gray.300');
  const errorMessage = getFieldErrorMessage(formState, field.name);

  // Note: This approach relies on _not_ validating on blur. If you were to desire that to be the behavior,
  // we'd most likely want to check for `touched` on `isInvalid` as well.
  return (
    <FormControl isInvalid={!!errorMessage} id={field.name} w={w} {...formControlProps}>
      <FormLabel hidden={hideLabel} {...labelProps}>
        {label}
        {isRequired && <RequiredAsterisk />}
      </FormLabel>
      <InputGroup borderColor={mode('gray.200', 'whiteAlpha.300')}>
        {icon && (
          <InputLeftElement pointerEvents="none" color={iconColor}>
            {icon}
          </InputLeftElement>
        )}
        {leftElement && <InputLeftElement>{leftElement}</InputLeftElement>}
        <ChakraInput isRequired={isRequired} w={w} {...field} {...inputProps} />
        {rightElement && <InputRightElement>{rightElement}</InputRightElement>}
      </InputGroup>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
}
