import { useAuth0 } from '@auth0/auth0-react';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import type { LogoutOptions } from 'auth0-js';
import { useCallback, useMemo } from 'react';
import { logout as logoutAction, setAuthData } from '@features/auth/actions';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useConfig } from '@hooks';
import { getBestUserName } from '@utils/getBestUserName';

const ROLES = {
  stord_employee: 99,
  admin: 5,
  supervisor: 4,
  icqa: 3,
  lead: 2,
  associate: 1,
};

type RoleType = keyof typeof ROLES;

const isKnownRole = (role: string): role is RoleType => role in ROLES;

export function useAuth() {
  const {
    user,
    loginWithRedirect,
    getIdTokenClaims,
    isLoading,
    logout: auth0Logout,
    isAuthenticated: isAuthenticatedWithAuth0,
  } = useAuth0();
  const { AUTH0_CLIENT_ID } = useConfig();
  const dispatch = useAppDispatch();

  const { token, role } = useAppSelector((state) => state.auth);

  const bestAvailableDisplayName = getBestUserName(user);

  const combinedLogout = useCallback(
    (options?: LogoutOptions) => {
      dispatch(logoutAction());
      auth0Logout({
        client_id: AUTH0_CLIENT_ID,
        returnTo: `${window.location.origin}/login`,
        ...options,
      });
    },
    [dispatch, auth0Logout, AUTH0_CLIENT_ID]
  );

  const cypressAuth = window.Cypress && window.localStorage.getItem('auth0Cypress');

  const isAuthenticated = isAuthenticatedWithAuth0 || !!cypressAuth;
  const isStordEmployee = role === 'stord_employee';

  const isAuthorized = useCallback(
    (minimumRole: keyof typeof ROLES) =>
      isKnownRole(role) ? ROLES[role] >= ROLES[minimumRole] : false,
    [role]
  );

  return useMemo(
    () => ({
      isAuthenticated,
      isAuthorized,
      isLoading,
      loginWithRedirect,
      getIdTokenClaims,
      logout: combinedLogout,
      user: window.Cypress ? JSON.parse(cypressAuth) : user,
      bestAvailableDisplayName,
      token,
      role,
      // stord_employees should be considered as 'super-admins'
      isAdmin: role === 'admin' || isStordEmployee,
      isSupervisor: role === 'supervisor',
      isLead: role === 'lead',
      isAssociate: role === 'associate',
      isStordEmployee,
      ...bindActionCreators({ setAuthData }, dispatch),
    }),
    [
      bestAvailableDisplayName,
      combinedLogout,
      cypressAuth,
      dispatch,
      getIdTokenClaims,
      isAuthenticated,
      isAuthorized,
      isLoading,
      isStordEmployee,
      loginWithRedirect,
      role,
      token,
      user,
    ]
  );
}
