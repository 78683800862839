import type { TextareaProps as ChakraTextareaProps } from '@chakra-ui/react';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Textarea as ChakraTextarea,
  HStack,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import { useController } from 'react-hook-form';
import type { FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';
import { getFieldErrorMessage, RequiredAsterisk } from './utils';

export function RHFTextarea<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  hideLabel,
  isRequired,
  characterCounterLimit,
  // Controller props
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  ...textareaProps
}: UseControllerProps<TFieldValues, TName> &
  ChakraTextareaProps & {
    label: string;
    hideLabel?: boolean;
    isRequired?: boolean;
    readOnly?: boolean;
    characterCounterLimit?: number;
  }) {
  const { field, formState } = useController({
    defaultValue: '' as any, // This is to prevent silly jest errors regarding controlled switching to uncontrolled errors
    name,
    rules,
    shouldUnregister,
    control,
  });

  const errorMessage = getFieldErrorMessage(formState, field.name);
  const isFieldErrorPresent = !!errorMessage;

  const length = (field?.value as string | undefined)?.length ?? 0;

  const isInvalid =
    isFieldErrorPresent || !!(characterCounterLimit && length > characterCounterLimit);

  const redText = mode('red.500', 'red.300');
  const invalidFocusBorderColor = mode('red.500', 'red.300');

  // Note: This approach relies on _not_ validating on blur. If you were to desire that to be the behavior,
  // we'd most likely want to check for `touched` on `isInvalid` as well.
  return (
    <FormControl isInvalid={isInvalid} id={field.name}>
      <FormLabel hidden={hideLabel}>
        {label}
        {isRequired && <RequiredAsterisk />}
      </FormLabel>
      <ChakraTextarea
        bg={mode('white', 'darkmode.gray.800')}
        {...(characterCounterLimit && isInvalid && { focusBorderColor: invalidFocusBorderColor })}
        {...field}
        {...textareaProps}
      />
      <HStack align="center" justify="end" mt={isFieldErrorPresent ? 1 : 0}>
        {isFieldErrorPresent && (
          <FormErrorMessage mr="auto" mt={0}>
            {errorMessage}
          </FormErrorMessage>
        )}
        {characterCounterLimit && !textareaProps['isDisabled'] && (
          <FormHelperText {...(isInvalid && { color: redText })}>
            {length} / {characterCounterLimit}
          </FormHelperText>
        )}
      </HStack>
    </FormControl>
  );
}
