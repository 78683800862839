import React from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import {
  ConfigGate,
  DatadogProvider,
  ErrorBoundaryReloadOnDynamicImport,
  FeatureFlagProvider,
  Auth0Provider,
  SEO,
  AuthBuildingHydrator,
} from '@components';
import { store } from '@store';
import { ChakraProvider } from '@theme';
import { getChannelsPubSub } from '@utils';
import App from './App';

// Start the pubsub before anything else, so we don't have import order or circular dependencies issues :joy:
getChannelsPubSub();

function renderApp() {
  const app = document.getElementById('root')!;

  // create a root
  const root = createRoot(app);

  //render app to root
  root.render(
    <React.StrictMode>
      <ChakraProvider>
        <ErrorBoundaryReloadOnDynamicImport>
          <HelmetProvider>
            <Provider store={store}>
              <SEO titleTemplate="%s | Stord WMS" defaultTitle="Stord WMS" />
              <ConfigGate>
                <BrowserRouter>
                  <Auth0Provider>
                    <AuthBuildingHydrator>
                      <DatadogProvider version={__APP_VERSION__}>
                        <FeatureFlagProvider>
                          <App />
                        </FeatureFlagProvider>
                      </DatadogProvider>
                    </AuthBuildingHydrator>
                  </Auth0Provider>
                </BrowserRouter>
              </ConfigGate>
            </Provider>
          </HelmetProvider>
        </ErrorBoundaryReloadOnDynamicImport>
      </ChakraProvider>
    </React.StrictMode>
  );
}

renderApp();
