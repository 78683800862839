import { useGetShippersQuery } from '@store/services/api';
import type { GetShippersApiArg } from '@store/services/api.generated';
import { useMemo } from 'react';
import { MAX_PAGE_SIZE } from '@constants/paging';

export const useGetShipperOptions = (additionalParams?: GetShippersApiArg) => {
  const { data, isLoading, isFetching, isError, error } = useGetShippersQuery({
    active: true,
    pageSize: MAX_PAGE_SIZE,
    sort: 'name',
    ...additionalParams,
  });

  const shipperOptions = useMemo(
    () =>
      (data?.data ?? []).map((shipper) => {
        return {
          label: shipper?.name!,
          value: shipper?.id!,
        };
      }),
    [data]
  );

  const shipperMap = useMemo(
    () => new Map((data?.data ?? []).map((shipper) => [shipper.id!, shipper])),
    [data]
  );

  return {
    data,
    shipperMap,
    shipperOptions,
    isLoading: isLoading || isFetching,
    isError,
    error,
  };
};
