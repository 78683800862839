import { Badge, Flex, Heading, HStack } from '@chakra-ui/react';
import { NoTranslate } from '@components';
import { LpnLineItemDetails } from './LpnLineItemDetails';
import type { LocationLicensePlateSummary as LpnSchema } from '@store/services/api.generated';
import { Fragment, useMemo } from 'react';
import _ from 'lodash';

interface LicensePlateSummaryProps {
  lpnSummary?: LpnSchema;
}

const LpnItemsGroup = ({
  outboundExternalId,
  lpnLineItems,
}: {
  outboundExternalId?: string;
  lpnLineItems: LpnSchema;
}) => {
  return (
    <Fragment>
      {outboundExternalId !== 'noOutbound' && (
        <Flex alignItems="center" gridGap={4}>
          <Badge colorScheme="blue">
            <NoTranslate>Outbound: {outboundExternalId}</NoTranslate>
          </Badge>
        </Flex>
      )}
      <LpnLineItemDetails lineItems={lpnLineItems} />
    </Fragment>
  );
};

export function LicensePlateSummary({ lpnSummary }: LicensePlateSummaryProps) {
  const licensePlateSummary = useMemo(() => {
    return _.chain((lpnSummary ?? []).slice().sort((_a, b) => (b.outbound_id !== null ? 1 : -1)))
      .groupBy(({ lpn }) => lpn ?? '')
      .reduce((acc, lpnObjects, lpn) => {
        const result = _.groupBy(
          lpnObjects ?? [],
          ({ outbound_external_id }) => outbound_external_id ?? 'noOutbound'
        );
        return { ...acc, [lpn]: result };
      }, {})
      .value();
  }, [lpnSummary]);

  return (
    <div data-testid="location-license-plate-summary">
      {licensePlateSummary &&
        Object.entries(licensePlateSummary).map(([lpn, lpnObject]) => {
          return (
            <Fragment key={lpn}>
              <HStack justify="space-between" spacing={2} pt={2} data-testid="lpn-title">
                <Heading size="sm">
                  <NoTranslate>LPN: {lpn ?? '--'}</NoTranslate>
                </Heading>
              </HStack>
              {(lpnObject as LpnSchema) &&
                Object.entries(lpnObject as LpnSchema).map(
                  ([outboundExternalId, lpnLineItemObjects]) => {
                    return (
                      <LpnItemsGroup
                        key={outboundExternalId}
                        outboundExternalId={outboundExternalId}
                        lpnLineItems={lpnLineItemObjects as LpnSchema}
                      />
                    );
                  }
                )}
            </Fragment>
          );
        })}
    </div>
  );
}
