import { Divider, GridItem, SimpleGrid, Text } from '@chakra-ui/react';
import type { LocationLicensePlateSummary as LpnSchema } from '@store/services/api.generated';
import { Fragment } from 'react';

interface LpnLineItemDetailsProps {
  lineItems: LpnSchema;
}

export function LpnLineItemDetails({ lineItems }: LpnLineItemDetailsProps) {
  return (
    <SimpleGrid columns={1} gridTemplateColumns="auto auto" mt={5} columnGap={5}>
      <GridItem>
        <Text variant="lighter" fontSize="xs">
          SKU
        </Text>
      </GridItem>
      <GridItem>
        <Text variant="lighter" fontSize="xs">
          QTY
        </Text>
      </GridItem>
      <GridItem colSpan={2}>
        <Divider />
      </GridItem>
      {lineItems.map((li, index) => {
        return (
          <Fragment key={index}>
            <GridItem className="_no-translate">{li.sku ?? '--'}</GridItem>
            <GridItem>{li.sum_quantity ?? '--'}</GridItem>
          </Fragment>
        );
      })}
    </SimpleGrid>
  );
}
