import type { Item, Product } from '@store/services/api.generated';

export type ItemTrackingProperty = keyof Pick<
  Item,
  'lot_number' | 'expiration_date' | 'manufacturer_date'
>;

interface TrackableProperties
  extends Partial<Pick<Item, 'lot_number' | 'expiration_date' | 'manufacturer_date'>> {}

const TRACKING_CONFIG: Record<ItemTrackingProperty, keyof Product> = {
  lot_number: 'lot_tracked',
  expiration_date: 'expiration_date_tracked',
  manufacturer_date: 'manufacturer_date_tracked',
} as const;

const TRACKING_AND_RECORDING_CONFIG: Record<ItemTrackingProperty, keyof Product> = {
  ...TRACKING_CONFIG,
  lot_number: 'lot_recorded',
} as const;

const TRACKING_PROPERTIES = Object.keys(TRACKING_CONFIG) as ItemTrackingProperty[];

const TRACKING_AND_RECORDING_PROPERTIES = Object.keys(
  TRACKING_AND_RECORDING_CONFIG
) as ItemTrackingProperty[];

export const getTrackedProperties = (product?: Product): ItemTrackingProperty[] => {
  return TRACKING_PROPERTIES.filter((field) => product?.[TRACKING_CONFIG[field]] ?? false);
};

export const getTrackedAndRecordedProperties = (product?: Product): ItemTrackingProperty[] => {
  return TRACKING_AND_RECORDING_PROPERTIES.filter(
    (field) => product?.[TRACKING_AND_RECORDING_CONFIG[field]] ?? false
  );
};

export const doTrackedPropertiesMatch = (
  product: Product | undefined,
  item: Item | undefined,
  comparison: TrackableProperties | undefined
): boolean => getTrackedProperties(product).every((field) => item?.[field] === comparison?.[field]);

export const doTrackedAndRecordedPropertiesMatch = (
  product: Product | undefined,
  item: Item | undefined,
  comparison: TrackableProperties | undefined
): boolean =>
  getTrackedAndRecordedProperties(product).every((field) => item?.[field] === comparison?.[field]);
