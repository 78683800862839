import get from 'lodash/get';
import type { FieldError, FieldValues, FieldPath, UseFormStateReturn } from 'react-hook-form';

export const isFieldError = (error: any): error is FieldError => error && 'message' in error;

/**
 * Checks to see if the given field name exists in the errors object,
 * and will return the error message if it does.
 * @returns {String} The error message
 */
export const getFieldErrorMessage = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  formState: UseFormStateReturn<TFieldValues>,
  name: TName
) => {
  // Nested fields and array fields use dot notation names; get() will handle this
  const error = get(formState.errors, name);
  if (isFieldError(error)) {
    return error.message;
  }
};

export * from './RequiredAsterisk';
