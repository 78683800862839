import { Badge, Box, Flex, Link, Text } from '@chakra-ui/react';
import type { Location, Shipper } from '@store/services/api.generated';
import { useNavigate } from 'react-router-dom';
import { BarcodeBuddyResult, LineItemDetails } from './components';
import { NoTranslate } from '@components';
import { LicensePlateSummary } from '@components/LpnSummary/LicensePlateSummary';

export function LocationPreview({
  location,
  shipperMap,
  closeModal,
  handleClick = () => {},
}: {
  location: Location;
  shipperMap: Map<string | undefined, Shipper>;
  closeModal: () => void;
  handleClick?: () => void;
}) {
  const navigate = useNavigate();
  const directLink = `/settings/locations/${encodeURIComponent(location?.name!)}`;

  let badgeColor = '';
  switch (location.type) {
    case 'equipment':
      badgeColor = 'orange';
      break;
    case 'staging':
      badgeColor = 'green';
      break;
    case 'other':
      badgeColor = 'red';
      break;
    default:
      badgeColor = 'blue';
  }

  const lineItems = (location.product_summary ?? []).map((ps) => ({
    sku: ps.product?.sku,
    shipperName: shipperMap.get(ps.product?.shipper_id ?? '')?.name ?? '--',
    quantity: ps.items_quantity,
  }));

  return (
    <BarcodeBuddyResult
      type="location"
      directLink={directLink}
      closeModal={closeModal}
      handleClick={handleClick}
    >
      <Box py={1}>
        <Flex alignItems="center" gridGap={4}>
          <Text
            as={Link}
            onClick={() => {
              handleClick();
              navigate(directLink);
              closeModal();
            }}
            fontSize="xl"
            fontWeight="bold"
          >
            <NoTranslate>{location?.name ?? '--'}</NoTranslate>
          </Text>
          <Badge colorScheme={badgeColor}>{location?.type ?? '--'}</Badge>
        </Flex>

        <LineItemDetails lineItems={lineItems} closeModal={closeModal} />
        {location.license_plate_summary && (
          <LicensePlateSummary lpnSummary={location.license_plate_summary ?? []} />
        )}
      </Box>
    </BarcodeBuddyResult>
  );
}
